<template>
	<!-- 远动员人数 左边-->
	<div>
		<div class="container darkBack">
			<div class="whiteFont font20">数据分析</div>
			<div class="borderBack sportNum">
				<div class="lightBlueFont">全市运动员总数</div>
				<div class="blueFont font26">
					<countTo :startVal='0' :endVal='sportNum' :duration='3000'></countTo>
				</div>
			</div>
			<!-- 男女比例 -->
			<div class="sexRatio">
				<div class="blueFont font18">● 运动员男女比例</div>
				<div class="chartGroup">
					<!-- 男性表 -->
					<div class="manChart">
						<div class="legend">
							<img src="../../../assets/images/man.png">
							<p>男</p>
						</div>
						<!-- 图表 -->
						<div id="manChart"></div>
					</div>
					<!-- 女性表 -->
					<div class="womanChart">
						<div class="legend">
							<img src="../../../assets/images/woman.png">
							<p>女</p>
						</div>
						<!-- 图表 -->
						<div id="womanChart"></div>
					</div>
				</div>
			</div>
			<!-- 级别比例 -->
			<div>
				<div class="font18 blueFont">● 级别比例</div>
				<div id="levelProportion"></div>
			</div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";
	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
			return{
				sportNum:0,

				ratio:{
					man:{
						gender: '男',
						color1: '#43BA6B',        // 绿色
						color2: '#333453',
						value: '55',
						img:require('../../../assets/images/man.png'),
					},
					woman:{
						gender: '女',
						color1: '#FF79FF',        // 紫色
						color2: '#333453',
						value: '45',
						img:require('../../../assets/images/woman.png'),
					}
				},
				rank:{
					pieDatas:[
						{ "value": 30, "name": "运动健将" },
						{ "value": 14, "name": "国际运动健将" },
						{ "value": 26, "name": "三级" },
						{ "value": 20, "name": "二级" },
						{ "value": 10, "name": "一级" }
					]
				}
			}
		},
		methods:{
			// 绘制比例饼图
			pie(id, number){
				// 基于准备好的dom，初始化echarts实例
				// var  runningWater = this.$echarts.init(document.getElementById(id));
				let runningWater = this.$echarts.getInstanceByDom(document.getElementById(id))
				if (runningWater == null) { // 如果不存在，就进行初始化
					runningWater = this.$echarts.init(document.getElementById(id));
				}
		
				// 指定图表的配置项和数据
				var waterOption = {
			        // backgroundColor: '#F7F7F7',
			        //全局颜色，顺序对应每个 series
			        color: [number.color1,number.color2],
			        series: [{
			            name: '中间环形',
			            type: 'pie',
			            //圆，半径，值1内圆，值2外圆
			            radius: ['40%', '60%'],
			            //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
			            center: ['50%', '30%'],
			            avoidLabelOverlap: false,
			            hoverAnimation: false,
			            //数据标签
			            label: {
			                show: false,
			                position: 'center',
			                textStyle: {
			                    fontSize: "0.16rem",
			                    fontWeight: 'bold',
			                    color:'#fcf1cf',     // 中间字体的颜色
			                },
			                formatter: '{c}%',
			                // 针对 center 参数校正标签显示位置
			                lineHeight: 3
			            },
			            data: [{
			                    value: number.value,
			                    name: '',
			                    label: {
			                        normal: {
			                            show: true
			                        }
			                    }
			                },
			                {
			                    value: 100 - number.value,
			                    name: ''
			                }
			            ]
			        }]
			    };
			
			    // 使用刚指定的配置项和数据显示图表。
			    runningWater.setOption(waterOption)
			    // 图表自动缩放
			    window.addEventListener("resize",function(){
			        runningWater.resize();
			    });
			},
			// 绘制级别比例图
			drawLevelProportion(number){
				// let myChart = this.$echarts.init(document.getElementById("levelProportion"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("levelProportion"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("levelProportion"));
				}
				
				let series = [];
				let pieDatas = number;
				let maxRadius =  60,
				    barWidth = 5,
				    barGap =  5;
				let sumValue = 0;
				let showValue = true,showPercent = true;
				pieDatas.map(item => {
				    sumValue += item.value;
				})
				let barColor =  [
				        {"color1": "#AF5819",},
				        {"color1": "#BD54EB",},
				        {"color1": "#E6AF08",},
				        {"color1": "#19AF6D",},
				        {"color1": "#0E6DE9",}
				    ];
				pieDatas.map((item, i) => {
				    series.push({
				        type: 'pie',
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "30%", "50%"],
				        label: {show: false},
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: item.value,
				            name: item.name,
				            itemStyle: {
				                color: barColor[i].color1
				            }
				        }, {
				            value: sumValue - item.value,
				            name: '',
				            itemStyle: {
				                color: "transparent",
				            },
				            hoverAnimation: false
				        }]
				    })
				    series.push({
				        name: 'blank',
				        type: 'pie',
				        silent: true,
				        z: 0,
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "30%", "50%"],
				        label: {
				            show: false
				        },
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: 1,
				            itemStyle: {
				                color: "#0A0F5F",
				                borderWidth: 0
				            },
				            hoverAnimation: false
				        }]
				    });
				})
				let option = {
				    legend: {
				        show: true,
				        left: '60%',
				        top: 'middle',
				        itemWidth: 10,
				        itemHeight: 10,
				        itemGap:  10,
				        textStyle: {
				            fontSize:  12,
				            color:  '#A8DAEB',
				        },
				        formatter: (name) => {
							var datas = pieDatas;
							let total = 0;
							datas.map(item => {
								total += (item.value - 0)
							})
							let valueIndex = datas.map(item => item.name).indexOf(name);
							let num = ((datas[valueIndex].value / total) * 100).toFixed(2);
							if(isNaN(num)) num = 0;
							return name + "  " + (showPercent ? num + "%" : '');
						} ,
				    },
				    series: series,
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 获取数据
			getData(){
				let that = this;
				var data = {
                        token: window.getToken(),
                        app_id: window.appId,
                        time: timestamp(),
                    }
                    getAjax({
                        url: "/map/screen/getHomeSportsman",
                        method:"GET",
                        data:data,
                        success:function(res){
                            // console.log(res)
							// 运动员总数
							that.sportNum = res.data.sportsman_main_list.total;
							// 男女比例
							that.ratio.man.value = res.data.men_women_proportion[0].proportion;
							that.ratio.woman.value = res.data.men_women_proportion[1].proportion;
							that.pie('manChart',that.ratio.man);
							that.pie('womanChart',that.ratio.woman);
							// 级别比例
							that.rank.pieDatas = [];
							res.data.project_level.forEach((item,index)=>{
								that.rank.pieDatas.push({
									value:item.proportion,
									name:item.title
								})
							})
							that.drawLevelProportion(that.rank.pieDatas);
                        },
                    })
			}
		},
		mounted() {
			// this.pie('manChart',this.ratio.man);
			// this.pie('womanChart',this.ratio.woman);
			// this.drawLevelProportion(this.rank.pieDatas);
			this.getData();
		}
	}
</script>

<style scoped>
	.font26{
		font-size: 0.26rem
	}
	.container{
		width: 5.94rem;
		height: 7rem;
		padding: 0.2rem;
		position: relative;
	}
	.sportNum{
		width: 3.05rem;
		height: 1.01rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 10%;
		top: 4%;
	}
	.sexRatio{
		margin-top: 1.2rem;
		margin-bottom: 0rem;
	}
	.chartGroup{
		margin-top: 0.5rem;
		display: flex;
	}
	.manChart , .womanChart{
		width: 50%;
		display: flex;
	}
	.legend{
		width: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.1rem;
	}
	.legend > img{
		width: 0.62rem;
		height: 0.72rem;
	}
	.legend > p{
		font-size: 0.18rem;
		color: #7B8CB7;
		margin-top: 0.1rem;
	}
	#manChart , #womanChart{
		width: 1.70rem;
		height: 1.70rem;
	}
	#levelProportion{
		width: 5.00rem;
		height: 3.00rem;
	}
</style>